import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const Compose = () => import('./views/compose.vue');
const Edit = () => import('./views/edit.vue');
const Qiefen = () => import('./views/qiefen.vue');
const Read = () => import('./views/read.vue');
const Ziliao = () => import('./views/ziliao.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'compose',
        component: Compose,
    },
    {
        path: 'edit',
        component: Edit,
    },
    {
        path: 'qiefen',
        component: Qiefen,
    },
    {
        path: 'read',
        component: Read,
    },
    {
        path: 'ziliao',
        component: Ziliao,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;