import { RouteConfig } from 'vue-router';
const Yian = () => import('./yian/yian.vue');
import YianRouterChildren from './yian/yian.router';
const routers: RouteConfig[] = [
    {
        path: 'yian',
        component: Yian,
        children: YianRouterChildren
    },
    {
        path: '*',
        redirect: 'yian'
    },
];
export default routers;
