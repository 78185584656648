import { RouteConfig } from 'vue-router';
const Bentijiaoyan = () => import('./bentijiaoyan/bentijiaoyan.vue');
const Yuanwenjiaoyan = () => import('./yuanwenjiaoyan/yuanwenjiaoyan.vue');
const Jiaoyanguanli = () => import('./jiaoyanguanli/jiaoyanguanli.vue');
const Shuxinglaiyuan = () => import('./shuxinglaiyuan/shuxinglaiyuan.vue');
const YwglList = () => import('./ywgl/list.vue');
const StglList = () => import('./stgl/list.vue');
const YwshList = () => import('./ywsh/list.vue');
const YwshEdit = () => import('./ywsh/edit.vue');
const YwqfList = () => import('./ywqf/list.vue');
const YwqfEdit = () => import('./ywqf/edit.vue');
const YwqfSearch = () => import('./ywqf/search.vue');
import BentijiaoyanRouterChildren from './bentijiaoyan/bentijiaoyan.router';
import YuanwenjiaoyanRouterChildren from './yuanwenjiaoyan/yuanwenjiaoyan.router';
import JiaoyanguanliRouterChildren from './jiaoyanguanli/jiaoyanguanli.router';
import ShuxinglaiyuanRouterChildren from './shuxinglaiyuan/shuxinglaiyuan.router';
const routers: RouteConfig[] = [
    {
        path: 'bentijiaoyan',
        component: Bentijiaoyan,
        children: BentijiaoyanRouterChildren
    },
    {
        path: 'yuanwenjiaoyan',
        component: Yuanwenjiaoyan,
        children: YuanwenjiaoyanRouterChildren
    },
    {
        path: 'jiaoyanguanli',
        component: Jiaoyanguanli,
        children: JiaoyanguanliRouterChildren
    },
    {
        path: 'shuxinglaiyuan',
        component: Shuxinglaiyuan,
        children: ShuxinglaiyuanRouterChildren
    },
    {
        path: 'ywgl-list',
        name: 'ywgl-list',
        component: YwglList,
    },
    {
        path: 'stgl-list',
        name: 'stgl-list',
        component: StglList,
    },
    {
        path: 'ywsh-list',
        name: 'ywsh-list',
        component: YwshList,
    },
    {
        path: 'ywsh-edit',
        name: 'ywsh-edit',
        component: YwshEdit,
    },
    {
        path: 'ywqf-list',
        name: 'ywqf-list',
        component: YwqfList,
    },
    {
        path: 'ywqf-edit',
        name: 'ywqf-edit',
        component: YwqfEdit,
    },
    {
        path: 'ywqf-search',
        name: 'ywqf-search',
        component: YwqfSearch,
    },
    {
        path: '*',
        redirect: 'bentijiaoyan'
    },
];
export default routers;

