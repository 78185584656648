import { RouteConfig } from 'vue-router';
const Folder = () => import('./views/folder.vue');
const YianStudy = () => import('./views/study/yian-study.vue');
const TupuFolder = () => import('./views/tupu/tupu-folder.vue');
const JywjFolder = () => import('./views/jywj/jywj-folder.vue');
const FzjcFolder = () => import('./views/fzjc/fzjc-folder.vue');
const YianDetail = () => import('./views/study/yian-detail.vue');
const YianDetail2 = () => import('./views/study/yian-detail2.vue');
const JiaoxueFolder = () => import('./views/jiaoxue/jiaoxue-folder.vue');
const routers: RouteConfig[] = [
    {
        path: 'folder',
        component: Folder,
    },
    {
        path: 'yian-study',
        component: YianStudy,
    },
    {
        path: 'yian-detail',
        component: YianDetail,
    },
    {
        path: 'yian-detail2',
        component: YianDetail2,
    },
    {
        path: 'tupu-folder',
        component: TupuFolder,
    },
    {
        path: 'jywj-folder',
        component: JywjFolder,
    },
    {
        path: 'fzjc-folder',
        component: FzjcFolder,
    },
    {
        path: 'jiaoxue-folder',
        component: JiaoxueFolder,
    },
    {
        path: '*',
        redirect: 'folder'
    },
];
export default routers;

