import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 上传图片
 * @param vCom Vue组件
 */
const UploadImage = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/upload/image", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 上传视频
 * @param vCom Vue组件
 */
const UploadVideo = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/upload/file", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};

/**
 * @description 专病库列表
 * @param vCom Vue组件
 */
const GetCenter = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/center/list").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 创建人列表
 * @param vCom Vue组件
 */
const CreatorList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/center/creators", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取目录式控件的选项
 * @param vCom Vue组件
 */
const GetMulu = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/mulu").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 术语搜素（西医疾病、中医疾病、治法治则、中医证型、中医症状）
 * @param vCom Vue组件
 */
const GetTerms = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/terms", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @desc 获取面图数据
 */
const getFaceAnalysisData = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get('api/zwb/face/ai/detail', params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @desc 保存面图数据
 */
const PostFaceAnalysisData = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post('api/zwb/face/ai/detail', params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @desc 获取知识库列表
 */
const GetKuList = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get('api/knowledge/tables').then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 上传文件
 */
const UploadFile = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/upload/file", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取原文的来源选项
 * @url https://app.apifox.com/project/3433166/apis/api-187964867
 * @param search
 */
const GetYuanwenSource = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/book_source_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取原文的翻页列表
 * @url https://app.apifox.com/project/3433166/apis/api-187789629
 * @param 来源
 * @param 是否需要校验(true/false)
 * @param 分配状态
 * @param 校验状态
 * @param 页面类型(原文校验、原文审核、原文切分，不发就是那原文管理的)
 * @param check_user_id （校验员id）
 * @param search
 * @param user_id（创建人id）
 * @param categ_id
 */
const GetYuanwenList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/book_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 修改古籍信息(修改是否校验，分配校验员)
 * @url https://app.apifox.com/project/3433166/apis/api-127100234
 * @param book_id(必填)
 * @param 古籍名称
 * @param 古籍分类
 * @param image_url
 * @param 是否需要校验（false/true）
 * @param check_user_id (校验员的id)
 */
const PutBookDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.put("api/book/book", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 删除原文
 * @url https://app.apifox.com/project/3433166/apis/api-187990030
 * @param book_id
 */
const DelBook = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/book/book", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 书籍章节保存，提交审核，打回，审核通过
 * @url https://app.apifox.com/project/3433166/apis/api-188747388
 * @param title_id
 * @param action （保存，提交审核，打回修改，审核通过）
 * @param 正文
 * @param text （打回意见）
 * @param 章节名
 */
const PostBookVerify = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/knowledge/vertify", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 对章节进行高级搜索
 * @url https://app.apifox.com/project/3433166/apis/api-188774419
 * @param 检索内容
 * @param 朝代  
 * @param 作者
 * @param 古籍分类
 */
const PostBookPrimarySearch = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/knowledge/book_content/primary_search", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取实体的来源选项
 * @url https://app.apifox.com/project/3433166/apis/api-189167554
 * @url /api/annotator/conceptual_entity_source_list
 * @param search
 */
const GetShitiSource = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/conceptual_entity_source_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};

/**
 * @description 获取医家，朝代等可选的实体列表
 * @url https://app.apifox.com/project/3433166/apis/api-189234924
 * @api api/annotator/conceptual_entity_list
 * @param 语义类型id
 * @param 实体id
 * @param 语义名称 (中国朝代，中医医家等)
 */
const GetShitOption = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/conceptual_entity_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取有已通过章节的书籍的列表
 * @url https://app.apifox.com/project/3433166/apis/api-194707355
 * @api api/book/book_list_for_primary_search
 * @param search
 */
const GetPassBook = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/book/book_list_for_primary_search", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取子任务（不翻页）
 * @url https://app.apifox.com/link/project/3433166/apis/api-227479731
 * @api /api/annotator/task/list_all
 */
const GetChildTaskData = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/task/list_all", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 创建子任务
 * @url https://app.apifox.com/link/project/3433166/apis/api-227722975
 * @api /api/annotator/create_son_task
 */
const CreateChilTask = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/create_son_task", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
export {
    UploadImage, UploadVideo, GetCenter, CreatorList, GetMulu, GetTerms,
    getFaceAnalysisData, PostFaceAnalysisData, GetKuList, UploadFile,
    GetYuanwenSource, GetYuanwenList, PutBookDetail, DelBook, PostBookVerify,
    PostBookPrimarySearch,
    GetShitiSource, GetShitOption, GetPassBook, CreateChilTask, GetChildTaskData
}