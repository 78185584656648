export default {
    install(Vue: any) {
        // value 需要过滤的数据，例如[{id:1,name:'语文'},{id:1,name:'语文'}]
        // key 拼接字段1 
        // key2 拼接字段2,跟字段1之间用-分隔
        Vue.filter('objArrFormat', (value: any, key: any, key2?: any) => {
            if (!value || value.length == 0) {
                return '/'
            } else {
                if (!key) {
                    return value.join('、');
                } else {
                    let str: any = ''
                    value.forEach((ele: any) => {
                        if (str && (ele[key] || ele[key2])) {
                            str += '、'
                        }
                        if (ele[key]) {
                            str += ele[key];
                        }
                        if (key2) {
                            str += '-' + ele[key2]
                        }

                    });
                    return str
                }
            }
        });
        // value 需要过滤的数据，例如["数学", "语文", "英语"]
        // space 分隔符，默认、
        Vue.filter('strArrFormat', (value: any, space: any = '、') => {
            let str: any = '';
            if (value && value.length > 0) {
                value.forEach((ele: any) => {
                    if (str) {
                        str += space;
                    }
                    str += ele;
                });
            }
            return str;
        });
        // value 需要过滤的数据，例如"Wed Nov 29 2023 17:37:42 GMT+0800 (中国标准时间)"或者“2019-12-25 10:21:55”
        // format 格式
        // space 分隔符
        Vue.filter('dataFormat', (value: any, format: any = 'yyyy-MM-dd HH:mm:ss', space: any = '-') => {
            const now: any = new Date(value);
            const year: any = now.getFullYear(); // 得到年份
            let month: any = now.getMonth(); // 得到月份
            let date: any = now.getDate(); // 得到日期
            let hour: any = now.getHours(); // 得到小时
            let minu: any = now.getMinutes(); // 得到分钟
            let sec: any = now.getSeconds(); // 得到秒
            month = month + 1;
            if (month < 10) {
                month = "0" + month;
            }
            if (date < 10) {
                date = "0" + date;
            }
            if (hour < 10) {
                hour = "0" + hour;
            }
            if (minu < 10) {
                minu = "0" + minu;
            }
            if (sec < 10) {
                sec = "0" + sec;
            }
            let time = "";
            if (format == "yyyy") {
                time = year
            } else if (format == "yyyy-MM") {
                time = year + "-" + month
            } else if (format == "yyyy-MM-dd") {
                time = year + "-" + month + "-" + date
            } else if (format == "yyyy-MM-dd HH") {
                time = year + "-" + month + "-" + date + " " + hour
            } else if (format == "yyyy-MM-dd HH:mm") {
                time = year + "-" + month + "-" + date + " " + hour + ":" + minu
            } else if (format == "yyyy-MM-dd HH:mm:ss") {
                time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
            }
            return time;
        });
        /**
         * @description 性别过滤，0未知，1男，2女
         */
        Vue.filter('genderFormat', (value: any) => {
            if (value === 0) {
                return '未知'
            } else if (value === 1) {
                return '男'
            } else if (value === 2) {
                return '女'
            } else {
                return ''
            }
        });

    },
};